<template>
  <div class="col-12 col-md-6 text-left welcomeText">
    <h1>Verifikasi Akun</h1>
    <h5>
      Kami telah mengirimkan SMS ke <b>{{ telepon }}</b> dengan sebuah
      kode. Masukkan kode tersebut untuk verifikasi telepon anda. SMS bisa saja membutuhkan waktu beberapa saat (atau lebih lama) untuk terkirim ke Handphone anda.
    </h5>
    <h6 class="text-light">Pastikan nomor Handphone yang Anda masukkan aktif.</h6>
    <form action="">
      <div class="form-group mt-3">
        <input
          type=""
          class="form-control mb-2"
          aria-describedby=""
          placeholder="Masukan Kode"
          v-model="kodeVer"
        />
        
        <label class="text-white" for=""
          >Tidak menerima SMS?
          <b
            ><a href="" @click.prevent="resendCode()">Kirim ulang Kode</a></b
          ></label
        >
      </div>
      <button
        type="submit"
        class="btn btn-light mt-1"
        style="background-color: rgba(255, 255, 255, 0.5); color: white"
        @click.prevent="verifyCode"
      >
        <template v-if="loading">
          <div
            class="spinner-border spinner-border-sm text-primary"
            role="status"
          >
            <span class="sr-only"></span>
          </div>
          <span class="span-loading">Loading</span>
        </template>
        <h5 class="mb-0" v-if="!loading">verifikasi kode</h5>
      </button>
    </form>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      loading: false,
      kodeVer: "",
      regisId: "",
      telepon: "",
    };
  },
  methods: {
    resendCode() {
      axios
        .post(`/api/resend-registrasi-sms`, {
          userId: this.regisId,
        })
        .then(() => {
          this.$toast.success("Kode Dikirim Ulang");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verifyCode() {
      this.loading = true;
      axios
        .put(`/api/perujuksms`, {
          kodeKonfirmasi: this.kodeVer,
          userId: this.regisId,
        })
        .then(({ data }) => {
          sessionStorage.removeItem("verified");
          this.$toast.success("Verifikasi Berhasil");
          this.$router.push({ name: "LoginPage" });
        })
        .catch((err) => {
          this.$toast.error("Verifikasi Gagal");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    const sessionStorageItem = sessionStorage.getItem("verified");
    if (sessionStorageItem) {
      const item = JSON.parse(sessionStorageItem);
      this.telepon = item.telepon;
      this.regisId = item.regisId;
    }
  },
};
</script>

<style>
</style>